<style lang="less" scoped>
.top-item-row {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    width: 120px;
    white-space: nowrap;
  }
  .el-input {
    width: 340px;
    /deep/ .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
  .el-textarea {
    width: 340px;
  }
  .el-select {
    width: 340px;
  }
  .el-cascader {
    width: 340px;
  }
}
/deep/.dialog-content__mini {
  min-width: 35rem;
}
.dialog-main {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="治疗方案导出" v-show="isShow" @close="Close">
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        确认
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialog-main">
      <div class="top-item-row">
        <span>治疗方案：</span>
        <el-select class="input_item" v-model="moudleId">
          <el-option
            v-for="(item, index) in moudleList"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'

import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'DownloadInfoDialog',
  components: {
    CmDialogBorder
  },
  props: {
    moudleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      moudleId: ''
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    Show() {
      this.isShow = true
    },

    Save() {
      this.axios
        .get(`/v1/webconsole/downloadcenter/export/solutions/${this.moudleId}`, {
          responseType: 'blob'
        })
        .then((res) => {
          if (res.status === 200) {
            const { data } = res
            let filename = '治疗方案.xls'

            const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
            let dom = document.createElement('a')
            let url = window.URL.createObjectURL(blob)
            dom.href = url
            dom.download = decodeURI(filename)
            dom.style.display = 'none'
            document.body.appendChild(dom)
            dom.click()
            dom.parentNode.removeChild(dom)
            window.URL.revokeObjectURL(url)
          } else {
            this.$message({
              message: '导出失败',
              type: 'warning'
            })
          }
        })
        .finally(() => {
          this.Close()
        })
    },
    Close() {
      this.isShow = false
      this.moudleId = ''
    }
  }
}
</script>
