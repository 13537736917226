<style lang="less" scoped>
.top-item-row {
  margin: 10px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  span {
    width: 120px;
    white-space: nowrap;
  }
  .el-input {
    width: 340px;
    /deep/ .el-input__inner {
      height: 28px;
      line-height: 28px;
    }
  }
  .el-textarea {
    width: 340px;
  }
  .el-select {
    width: 340px;
  }
  .el-cascader {
    width: 340px;
  }
}
/deep/.dialog-content__mini {
  min-width: 42rem;
}
.dialog-main {
  margin-top: 10px;
  margin-bottom: 20px;
}
</style>

<template>
  <CmDialogBorder
    size="mini"
    :zIndex="zIndex"
    title="添加患者治疗方案"
    v-show="isShow"
    @close="Close"
  >
    <div class="crt-content">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
    <div class="dialog-main">
      <div class="top-item-row">
        <span>患者姓名：</span>
        <el-input v-model="solutionInfo.patientsName"></el-input>
      </div>
      <div class="top-item-row">
        <span>患者病案号：</span>
        <el-input v-model="solutionInfo.patientId"></el-input>
      </div>
      <div class="top-item-row">
        <span>治疗方案：</span>
        <el-select class="input_item" v-model="moudleId" @change="handleMoudle">
          <el-option
            v-for="(item, index) in moudleList"
            :key="index"
            :label="item.title"
            :value="item.id"
          ></el-option>
        </el-select>
      </div>
      <div class="top-item-row">
        <span>方案类型：</span>
        <el-cascader
          v-model="typeValue"
          :options="options"
          :props="{ expandTrigger: 'hover' }"
          @change="handleChange"
          :disabled="moudleId === ''"
        ></el-cascader>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '@/components/CmDialogBorder.vue'

import { PopupManager } from 'element-ui/lib/utils/popup'

export default {
  name: 'SolutionInforDialog',
  components: {
    CmDialogBorder
  },
  props: {
    moudleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShow: false,
      zIndex: PopupManager.nextZIndex(),
      solutionInfo: {
        patientId: null,
        firstDiagnosisSymptomSign: '',
        complication: '',
        chiefComplaint: '',
        typeName: '',
        typeCategory: '',
        disposeTitle: '',
        patientsName: ''
      },
      moudleId: '',
      typeValue: [],
      options: []
    }
  },
  created() {},
  mounted() {},
  computed: {},
  methods: {
    handleMoudle() {
      this.typeValue = []

      this.$api.get(`/v1/webconsole/solution/disease/solutionType/${this.moudleId}`).then((res) => {
        if (res.data && res.data.data) {
          const cascaderData = {}
          // 遍历原始数组，按照 typeCategory 进行分组
          res.data.data.forEach((item) => {
            if (!cascaderData[item.typeCategory]) {
              cascaderData[item.typeCategory] = []
            }
            cascaderData[item.typeCategory].push({
              label: item.typeName,
              value: item.typeName
            })
          })
          // 将分组后的数据转换为级联选择框需要的格式
          const cascaderOptions = Object.keys(cascaderData).map((category) => ({
            label: category,
            value: category,
            children: cascaderData[category]
          }))
          this.options = cascaderOptions
        }
      })
    },
    handleChange() {
      this.solutionInfo.typeCategory = this.typeValue[0]
      this.solutionInfo.typeName = this.typeValue[1]
    },
    Show() {
      this.isShow = true
    },

    Save() {
      if (!this.solutionInfo.patientId) {
        return this.$message.warning('请填写患者病案号')
      }
      if (!this.moudleId) {
        return this.$message.warning('请选择治疗方案')
      }
      let data = {
        patientsID: this.solutionInfo.patientId,
        diseaseModule: this.moudleId,
        firstDiagnosisSymptomSign: this.solutionInfo.firstDiagnosisSymptomSign,
        complication: this.solutionInfo.complication,
        chiefComplaint: this.solutionInfo.chiefComplaint,
        typeName: this.solutionInfo.typeName,
        typeCategory: this.solutionInfo.typeCategory,
        title: this.solutionInfo.disposeTitle,
        patientsName: this.solutionInfo.patientsName
      }

      this.$api.post(`/v1/webconsole/solution/add/${this.moudleId}`, data).then((res) => {
        if (res.data && res.data.data) {
          this.$message.success('添加成功')
          this.$emit('save')
          this.openNewPage(res.data.data)
          this.Close()
        } else {
          this.$message.warning(res.data.msg)
        }
      })
    },
    openNewPage(row) {
      sessionStorage.setItem('patientIndex', row.patientIndex)
      localStorage.setItem('patientInfo', JSON.stringify(row))
      const { href } = this.$router.resolve({
        path: `/Treatment/Details?idx=${row.typeCategory}&id=${row.id}&isEdit=false`
      })
      window.open(href, '_blank')
    },
    Close() {
      this.typeValue = []
      this.moudleId = ''
      this.solutionInfo = {
        patientId: '',
        firstDiagnosisSymptomSign: '',
        complication: '',
        chiefComplaint: '',
        typeName: '',
        typeCategory: '',
        disposeTitle: ''
      }
      this.isShow = false
    }
  }
}
</script>
