<style lang="less" scoped>
.solution {
  .surgery-show {
    .surgery-show-content {
      .el-pagination {
        margin-top: 5px;
        text-align: center;
      }
    }
    .el-table {
      border-left: none;
      border-right: none;
    }
  }

  .header-second {
    height: 45px;
    display: flex;
    box-shadow: 0px 2px 2px #ccc;
    margin-bottom: 15px;
    padding: 0 20px;
    align-items: center;
    justify-content: space-between;
    .second-left {
      display: flex;
      align-items: center;
      .btn .el-button {
        font-size: 14px;
        .icon-rusuodengji:before {
          margin-right: 3px;
        }
        .icon-yuntongbu::before {
          margin-right: 3px;
        }
      }
    }
    .second-right {
      display: flex;
      align-items: center;
      .btn .el-button {
        font-size: 14px;
        .icon-rusuodengji:before {
          margin-right: 3px;
        }
        .icon-yuntongbu::before {
          margin-right: 3px;
        }
      }
    }
  }
  .surgery-search-conditions {
    margin-bottom: 15px;
    .input-form {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;

        .el-date-editor .el-range__icon {
          line-height: 19px;
        }
        .el-date-editor .el-range-separator {
          line-height: 19px;
        }
        .el-date-editor .el-range__close-icon {
          line-height: 19px;
        }
      }

      .input-form-item-label {
        text-align: center;
        width: 120px;
      }

      .input-form-item-content {
        width: 200px;
        & .el-select {
          width: 200px;
        }
      }
    }
  }
  .surgery-status {
    position: relative;
    height: 600px;
    .surgery-total {
      position: absolute;
      right: 50px;
      top: 4px;
      font-size: 15px;
    }
    .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
      color: red;
      font-size: 14px;
    }
    .status-noSchedule {
      color: red;
      margin-left: 10px;
      cursor: pointer;
    }
  }
}
</style>

<template>
  <div class="solution">
    <IndexHeader />
    <div class="header-second">
      <div class="second-left">
        <div class="btn">
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            v-debounce="addPatient"
            icon="el-icon-refresh-left"
          >
            添加患者
          </el-button>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            v-debounce="clearTable"
            icon="el-icon-refresh-left"
          >
            清空
          </el-button>
          <el-button
            type="primary"
            size="mini"
            class="commonBtn"
            v-debounce="getPatientsList"
            icon="el-icon-search"
          >
            查询
          </el-button>
        </div>
      </div>
      <div class="second-right">
        <div class="btn">
          <el-button
            type="primary"
            size="mini"
            icon="el-icon-download"
            class="commonBtn"
            @click="solutionDownload"
          >
            治疗方案导出
          </el-button>
        </div>
      </div>
    </div>

    <div class="surgery-search-conditions">
      <div class="input-form">
        <div class="input-form-item">
          <div class="input-form-item-label">病人姓名</div>
          <div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="string"
              v-model="solutionInfor.patientName"
              @change="changeNameSearch"
              size="mini"
            ></el-input>
          </div>
        </div>

        <div class="input-form-item">
          <div class="input-form-item-label">病案号</div>
          <div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="string"
              v-model="solutionInfor.patientId"
              @change="changeIdSearch"
              size="mini"
            ></el-input>
          </div>
        </div>

        <div class="input-form-item" v-show="isShowId">
          <div class="input-form-item-label">身份证号</div>
          <div>
            <el-input
              class="input-form-item-content"
              placeholder="请输入"
              type="string"
              v-model="solutionInfor.idcard"
              @change="changeIdCardSearch"
              size="mini"
            ></el-input>
          </div>
        </div>
      </div>
      <solution-infor-dialog
        ref="solutionInforDialog"
        :moudleList="moudleList"
        @save="dialogSave"
      ></solution-infor-dialog>
    </div>
    <div class="surgery-status">
      <el-tabs v-model="tabs_index" type="card" @tab-click="handleClick">
        <el-tab-pane label="所有" name="0">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table :data="tableData" border style="width: 100%" :height="tableHeight">
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column align="center" label="患者姓名" width="200">
                  <template slot-scope="scope" v-if="scope.row.patientsName">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="100"
                ></el-table-column>
                <el-table-column align="center" label="年龄" show-overflow-tooltip width="100">
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="180"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsCertificate"
                  label="身份证号"
                  min-width="220"
                ></el-table-column>
                <!-- <el-table-column
                  align="center"
                  prop=""
                  label="入院诊断"
                  min-width="180"
                ></el-table-column> -->
                <el-table-column
                  align="center"
                  prop="doctorName"
                  label="收治医生"
                  width="180"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="leaderName"
                  label="三级医生"
                  width="180"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="contactPhone"
                  label="联系人电话"
                  width="180"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="userInfo_all.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userInfo_all.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userInfo_all.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="未来30天来院复诊" name="1">
          <div class="surgery-show">
            <div class="surgery-show-content">
              <el-table :data="followupTableData" border style="width: 100%" :height="tableHeight">
                <el-table-column align="center" type="index" width="30"></el-table-column>
                <el-table-column align="center" label="患者姓名" width="200">
                  <template slot-scope="scope" v-if="scope.row.patientsName">
                    <el-button @click="checkPatient(scope.row)" type="text" size="small">
                      {{ scope.row.patientsName }}
                    </el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsSex"
                  label="性别"
                  width="100"
                ></el-table-column>
                <el-table-column align="center" label="年龄" show-overflow-tooltip width="100">
                  <template slot-scope="scope">
                    <span v-if="scope.row.ageDays">
                      {{ calculateAgeFromDays(scope.row.ageDays) }}
                    </span>
                    <span v-else>
                      {{ scope.row.patientsAge ? scope.row.patientsAge + '岁' : '-' }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsID"
                  label="病案号"
                  width="180"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="patientsCertificate"
                  label="身份证号"
                  min-width="220"
                ></el-table-column>
                <!-- <el-table-column
                  align="center"
                  prop=""
                  label="入院诊断"
                  min-width="180"
                ></el-table-column> -->
                <el-table-column
                  align="center"
                  prop="doctorName"
                  label="收治医生"
                  width="180"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="leaderName"
                  label="三级医生"
                  width="180"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="contactPhone"
                  label="联系人电话"
                  width="180"
                ></el-table-column>
              </el-table>
              <!-- 分页区域 -->
              <el-pagination
                @size-change="handleSizeChange1"
                @current-change="handleCurrentChange1"
                :current-page="userFollowupInfo_all.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="userFollowupInfo_all.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="userFollowupInfo_all.total"
              ></el-pagination>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <download-info-dialog ref="DownloadInfoDialog" :moudleList="moudleList"></download-info-dialog>
  </div>
</template>

<script>
import IndexHeader from '../../components/IndexHeader.vue'
import SolutionInforDialog from './components/SolutionInforDialog.vue'
import DownloadInfoDialog from './components/DownloadInfoDialog.vue'

export default {
  name: 'Solution',
  components: {
    IndexHeader,
    SolutionInforDialog,
    DownloadInfoDialog
  },
  data() {
    return {
      date: [],
      solutionInfor: {
        patientName: '',
        patientId: '',
        idcard: ''
      },
      isShowId: true,
      tabs_index: '0',
      tableData: [],
      followupTableData: [],
      tableHeight: window.innerHeight - 280, //表格动态高度
      screenHeight: window.innerHeight, //内容区域高度
      userInfo_all: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      userFollowupInfo_all: {
        pagenum: 1,
        pagesize: 15,
        total: 0
      },
      days: 30,
      moudleList: []
    }
  },
  created() {
    this.get_localtime()
    this.getPatientsList()
    this.getFollowupList()
    this.getMoudleList()
  },
  mounted() {
    window.onresize = () => {
      return () => {
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
  },

  watch: {
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 280
    }
  },

  methods: {
    getMoudleList() {
      this.$api.get('/v1/webconsole/solution/disease/modules').then((res) => {
        this.moudleList = res.data.data
      })
    },
    solutionDownload() {
      this.$refs.DownloadInfoDialog.Show()
    },
    calculateAgeFromDays(days) {
      if (days) {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()

        const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
        const birthYear = birthDate.getFullYear()

        let age = currentYear - birthYear

        // 检查生日是否已经过了，如果还没过，则减去一年
        if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
          age--
        }

        if (age < 1) {
          return days + '天'
        } else if (age < 2) {
          const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
          return '1年零' + remainingDays + '天'
        } else {
          return age + '岁'
        }
      } else {
        return ''
      }
    },
    addPatient() {
      this.$refs.solutionInforDialog.Show()
    },
    dialogSave() {
      this.getPatientsList()
    },
    // 查询条件清空
    clearTable() {
      this.solutionInfor = {
        patientName: '',
        patientId: '',
        idcard: ''
      }
      this.getPatientsList()
      this.getFollowupList()
    },
    get_localtime() {
      const today = new Date()
      const year = today.getFullYear()
      const month = today.getMonth() + 1
      const day = today.getDate()
      this.solutionInfor.studyStartDate =
        year + '-' + month + '-' + day + ' ' + 0 + ':' + 0 + ':' + 0
      this.solutionInfor.studyEndDate =
        year + '-' + month + '-' + day + ' ' + 23 + ':' + 59 + ':' + 59
      this.date = [this.solutionInfor.studyStartDate, this.solutionInfor.studyEndDate]
    },
    // 根据姓名查列表
    changeNameSearch(val) {
      this.solutionInfor.patientName = val
      if (this.tabs_index === '0') {
        this.getPatientsList()
      } else {
        this.getFollowupList()
      }
    },
    // 根据病案号查列表
    changeIdSearch(val) {
      this.solutionInfor.patientId = val
      if (this.tabs_index === '0') {
        this.getPatientsList()
      } else {
        this.getFollowupList()
      }
    },
    // 根据身份证号查列表
    changeIdCardSearch(val) {
      this.solutionInfor.idcard = val
      if (this.tabs_index === '0') {
        this.getPatientsList()
      } else {
        this.getFollowupList()
      }
    },
    checkPatient(row) {
      sessionStorage.setItem('patientIndex', row.patientsIndex)
      localStorage.setItem('patientInfo', JSON.stringify(row))
      const { href } = this.$router.resolve({
        path: `/Treatment`
      })
      window.open(href, '_blank')
    },
    handleSizeChange(newSize) {
      this.userInfo_all.pagesize = newSize
      this.getPatientsList()
    },
    handleSizeChange1(newSize) {
      this.userFollowupInfo_all.pagesize = newSize
      this.getFollowupList()
    },
    handleCurrentChange(newPage) {
      this.userInfo_all.pagenum = newPage
      this.getPatientsList()
    },
    handleCurrentChange1(newPage) {
      this.userFollowupInfo_all.pagenum = newPage
      this.getFollowupList()
    },
    // 首页获取病人列表
    getPatientsList() {
      let data = new FormData()
      if (this.solutionInfor.patientName) {
        data.append('name', this.solutionInfor.patientName)
      }
      if (this.solutionInfor.patientId) {
        data.append('patientID', this.solutionInfor.patientId)
      }
      if (this.solutionInfor.idcard) {
        data.append('idcard', this.solutionInfor.idcard)
      }
      data.append('current', this.userInfo_all.pagenum)
      data.append('size', this.userInfo_all.pagesize)
      this.$api.post(`/v1/webconsole/solution/patients/list`, data).then((res) => {
        this.tableData = res.data.data.records
        this.userInfo_all.total = res.data.data.total
      })
    },
    getFollowupList() {
      let data = new FormData()
      if (this.solutionInfor.patientName) {
        data.append('name', this.solutionInfor.patientName)
      }
      if (this.solutionInfor.patientId) {
        data.append('patientID', this.solutionInfor.patientId)
      }
      if (this.solutionInfor.idcard) {
        data.append('idcard', this.solutionInfor.idcard)
      }
      data.append('current', this.userFollowupInfo_all.pagenum)
      data.append('size', this.userFollowupInfo_all.pagesize)
      this.$api.post(`/v1/webconsole/solution/patients/followup/${this.days}`, data).then((res) => {
        this.followupTableData = res.data.data.records
        this.userFollowupInfo_all.total = res.data.data.total
      })
    },
    handleClick(tab) {
      this.tabs_index = tab.index
      this.solutionInfor = {
        patientName: '',
        patientId: '',
        idcard: ''
      }
      if (this.tabs_index === '0') {
        this.isShowId = true
        this.getPatientsList()
      } else {
        this.isShowId = false
        this.getFollowupList()
      }
    }
  }
}
</script>
